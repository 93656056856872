import React from 'react'
import { Container } from "reactstrap";
class Disclaimer extends React.Component {
  render() {
    return (
      <Container fluid>
        <h2>Website Disclaimer of <span className="website_url">datascore.int.kit.edu</span></h2>
        <p>Thank you for visiting the <span className="website_name">DataScore</span>. Our Website Disclaimer was created by <a href="https://www.disclaimer-template.com">Disclaimer Template </a> for <span className="website_url">datascore.int.kit.edu</span></p>
        <p>Please note that the information provided on <span className="website_url">datascore.int.kit.edu</span> and other related web properties are for informational purposes only.</p>
        <p>The information is provided by <span className="website_url">datascore.int.kit.edu</span> and whilst we endeavor to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
        <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of or in connection with the use of this website.</p>
        <p>Through this website you are able to link to other websites which are not under the control of <span className="website_url">datascore.int.kit.edu</span>. We have no control over the nature, content and availability of those websites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
        <p>Every effort is made to keep the website up and running smoothly. However, <span className="website_name">DataScore</span> takes no responsibility for and will not be liable for the website being temporarily unavailable due to technical issues beyond our control.</p>
        <p>Our Website is provided by <span className="website_name">DataScore</span> on an "AS IS" basis. Our Privacy Policy was created by <a href="https://www.generateprivacypolicy.com/">the Privacy Policy Generator</a>.</p>
        <p><span className="website_name">DataScore</span> makes no representations or warranties of any kind, express or implied as to the operation of the site, the information, content, materials or products included on the site.</p>
        <p>To the full extent permissible by applicable law, <span className="website_name">DataScore</span> disclaims all warranties, express or implied, including, but not limited to, the implied warranties and/or conditions of merchantability or satisfactory quality and fitness for a particular purpose and non-infringement.</p>
        <p><span className="website_name">DataScore</span> will not be liable for any damages of any kind arising from the use of the site, including, but not limited to direct, indirect, incidental, punitive and consequential damages.</p>
        <p><span className="website_name">DataScore</span> does not warrant that the site, or the server that makes it available, is free of viruses or other harmful components.</p>
      </Container >

    );
  }
}
export default Disclaimer
